import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Player from '../components/player';

interface MatchParams {
  stream: string;
}

export type VODProps = RouteComponentProps<MatchParams>;

export interface VODState {
  source: string;
  isAuthenticated: boolean;
  password: string;
  error?: string;
}

class VOD extends React.Component<VODProps, VODState> {
  constructor(props: VODProps) {
    super(props);

    const {
      match: {
        params: { stream },
      },
    } = this.props;

    this.state = {
      source: `https://media4.hostin.cc/maandonks_vod/${stream}.mp4/playlist.m3u8`,
      isAuthenticated: false,
      password: '',
    };
  }

  componentDidMount() {
    document.title = 'Video';
  }

  needsToBeAuthenticated = (stream: string) => ['ingrado'].includes(stream);

  authenticate = () => {
    const { password } = this.state;

    if (password === 'Ingr@do2022') {
      this.setState({ isAuthenticated: true });
    } else {
      this.setState({ error: 'Het wachtwoord is onjuist', password: '' });
    }
  };

  render() {
    const {
      match: {
        params: { stream },
      },
    } = this.props;
    const { source, isAuthenticated, password, error } = this.state;

    return this.needsToBeAuthenticated(stream) && !isAuthenticated ? (
      <div className="container">
        <div className="mt-5" />
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Wachtwoord</h5>
            <h6 className="card-subtitle mb-4 text-muted">
              Voer het wachtwoord in om de video te bekijken
            </h6>
            {error && <div className="alert alert-danger">{error}</div>}
            <form
              className="form"
              onSubmit={(e) => {
                e.preventDefault();

                this.authenticate();
              }}
            >
              <div className="row">
                <div className="col-10">
                  <input
                    className={`form-control${error ? ' border-danger' : ''}`}
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) =>
                      this.setState({
                        password: e.target.value,
                        error: undefined,
                      })
                    }
                  />
                </div>
                <div className="col-2">
                  <button
                    type="submit"
                    className="btn btn-outline-success btn-block"
                  >
                    Login
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    ) : (
      <div className="player-container">
        <Player
          source={source}
          // hls={false}
          controls={[
            'play-large',
            'play',
            'progress',
            'current-time',
            'volume',
            'fullscreen',
          ]}
        />
      </div>
    );
  }
}

export default VOD;
