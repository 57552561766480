import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Player from '../components/player';

interface MatchParams {
  stream: string;
}

export type LiveProps = RouteComponentProps<MatchParams>;

export interface LiveState {
  source: string;
}

class Live extends React.Component<LiveProps, LiveState> {
  constructor(props: LiveProps) {
    super(props);

    const {
      match: {
        params: { stream },
      },
    } = this.props;

    const bunny =
      'https://multiplatform-f.akamaihd.net/i/multi/will/bunny/big_buck_bunny_,640x360_400,640x360_700,640x360_1000,950x540_1500,.f4v.csmil/master.m3u8';

    this.state = {
      source:
        stream === 'test-stream'
          ? bunny
          : `https://maandonks-k.cdn.hostin.cc/ngrp:${stream}_live/playlist.m3u8`,
    };
  }

  componentDidMount() {
    document.title = 'Livestream';
  }

  render() {
    const { source } = this.state;

    return (
      <div className="container">
        <Player
          source={source}
          controls={[
            'play-large',
            'play',
            'mute',
            'volume',
            'settings',
            'fullscreen',
          ]}
        />
      </div>
    );
  }
}

export default Live;
