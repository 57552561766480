import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import Live from './content/pages/live';
import VOD from './content/pages/vod';

const App: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path="/live/:stream" component={Live} />
        <Route path="/vod/:stream" component={VOD} />
        <Route
          render={() => {
            window.location.href = 'https://www.maandonks.nl/';
            return null;
          }}
        />
      </Switch>
    </>
  );
};

export default App;
