import * as React from 'react';
import Plyr from 'plyr';
import Hls from 'hls.js';

import poster from '../../../assets/images/poster.png';

export interface PlayerProps {
  className?: string;
  style?: React.CSSProperties;
  source: string;
  hls?: boolean;
  controls?: string[];
  updatePlayerHeight?: () => void;
}

export type PlayerState = Record<string, unknown>;

class Player extends React.Component<PlayerProps, PlayerState> {
  player: Plyr | undefined;

  componentDidMount() {
    const { source: src, hls: loadHls = true, controls = [] } = this.props;
    const video = document.querySelector('video');

    if (video) {
      const options: Record<string, unknown> = {
        controls,
        settings: [],
        fullscreen: {
          enabled: true,
          iosNative: true,
          // fallback: 'force',
        },
      };

      if (loadHls && Hls.isSupported()) {
        const hls = new Hls();

        // const updateQuality = (quality: number) => {
        //   hls.levels.forEach((level, index) => {
        //     if (level.bitrate === quality * 1000000) hls.currentLevel = index;
        //   });
        // };

        hls.loadSource(src);

        // hls.on(Hls.Events.MANIFEST_PARSED, (_event, _data) => {
        //   const availableQualities = hls.levels
        //     .map(({ bitrate }) => {
        //       if (bitrate === 3500000) return 1080;
        //       if (bitrate === 2000000) return 720;
        //       return 480;
        //     })
        //     .sort((a, b) => b - a);

        //   options.quality = {
        //     default: availableQualities[0],
        //     options: availableQualities,
        //     forced: true,
        //     onChange: (e: number) => updateQuality(e),
        //   };

        this.player = new Plyr(video, options);
        this.registerPlayerEventHandlers();
        // });

        hls.attachMedia(video);
      } else {
        this.player = new Plyr(video, options);
        this.registerPlayerEventHandlers();

        this.player.source = {
          type: 'video',
          sources: [
            {
              src,
              provider: 'html5',
            },
          ],
        };
      }
    }
  }

  componentDidUpdate() {
    const { updatePlayerHeight } = this.props;

    if (updatePlayerHeight) updatePlayerHeight();
  }

  registerPlayerEventHandlers = () => {
    //
  };

  render() {
    const { className, style } = this.props;

    return (
      <video
        className={className}
        style={style}
        preload="none"
        id="player"
        poster={poster}
        crossOrigin="true"
        controls
        playsInline
      />
    );
  }
}

export default Player;
